
import React, { useEffect, useState } from "react";
import "../css/Home.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Screen1 from "../assets/screen1.png";
import Screen2 from "../assets/screen2.png";
import Screen3 from "../assets/screen3.png";
import Screen4 from "../assets/screen4.png";
import Screen5 from "../assets/screen5.png";
import Screen6 from "../assets/screen6.png";
import Screen7 from "../assets/screen7.png";
import Screen8 from "../assets/screen8.png";
import Screen9 from "../assets/screen9.png";

import Mob1 from "../assets/mob1.png";
import Mob2 from "../assets/mob2.png";
import Mob3 from "../assets/mob3.png";
import Mob4 from "../assets/mob4.png";
import Mob5 from "../assets/mob5.png";
import Mob6 from "../assets/mob6.png";
import Mob7 from "../assets/mob7.png";


function Home() {
  const [input, setInput] = useState({
    email: "",
    kakao: "",
  });
  const [buttonState, setButtonState] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  function handleClick(event) {
    event.preventDefault();
    setButtonState(true)
    const newSubmit = {
      email: input.email,
      kakao: input.kakao,
    };
    axios.post("http://bokjimap.com/api/post", newSubmit).then(
      alert('신청이 완료되었습니다')
    )
    setInput({
      email: "",
      kakao: "",
    });
    
  }



  return (
    <div>
    <div className="home-container">
      <img src={Screen1} alt="Logo" className="image-container" />
      <img src={Screen2} alt="Logo" className="image-container" />
      <img src={Screen3} alt="Logo" className="image-container" />
      <img src={Screen4} alt="Logo" className="image-container" />
      <img src={Screen5} alt="Logo" className="image-container" />
      {/* <img src={Screen6} alt="Logo" className="image-container" /> */}
      <div className="first-banner">
        <div className="first-banner-container">
        <div className="first-banner-text">
        지금 바로 신청하세요! <br/>서비스가 시작되면 알림을 보내드립니다
        </div>


        <form className="form-container">
        <div className="form-title">이메일</div>
          <div className="form-group">
            <input
              onChange={handleChange}
              name="email"
              value={input.email}
              autoComplete="off"
              className="form-control"
              placeholder="이메일 주소를 입력해주세요."
            ></input>
          </div>
          <div className="text1">
            혹은
            </div>
          <div className="form-group">
          <div className="form-title">카카오톡</div>
            <input
              onChange={handleChange}
              name="kakao"
              value={input.kakao}
              autoComplete="off"
              className="form-control"
              placeholder="카카오톡 아이디를 입력해주세요."
            ></input>
          </div>
          <div className="form-title"><input type="checkbox"/> 본 정보는 제3자에게 제공되지 않으며 서비스 시작시 알림용으로만 사용됩니다.</div>
          
          <button disabled={buttonState} onClick={handleClick} className={buttonState===false ? "btn btn-lg btn-info" : ""}>
          신청하기
          </button>

        </form>
        </div>
      </div>
      <img src={Screen7} alt="Logo" className="image-container" />
      {/* <img src={Screen8} alt="Logo" className="image-container" /> */}
      <img src={Screen9} alt="Logo" className="image-container" />

    </div>



<div className="mob-container">
  <img src={Mob1} alt="Logo" className="image-container" />
      <img src={Mob2} alt="Logo" className="image-container" />
      <img src={Mob3} alt="Logo" className="image-container" />
      <img src={Mob4} alt="Logo" className="image-container" />
      <img src={Mob5} alt="Logo" className="image-container" />
      <div className="mob-banner">
        <div className="title">
        지금 바로 신청하세요!
        </div>
        <div className="title-small">
        서비스가 시작되면 알림을 보내드립니다
        </div>
        
        <form className="form-container">
        <div className="form-title">이메일</div>
          <div className="form-group">
            <input
              onChange={handleChange}
              name="email"
              value={input.email}
              autoComplete="off"
              className="form-control"
              placeholder="이메일 주소를 입력해주세요."
            ></input>
          </div>
          <div className="text1">
            혹은
            </div>
          <div className="form-group">
          <div className="form-title">카카오톡</div>
            <input
              onChange={handleChange}
              name="kakao"
              value={input.kakao}
              autoComplete="off"
              className="form-control"
              placeholder="카카오톡 아이디를 입력해주세요."
            ></input>
          </div>
          <div className="form-title-small"><input type="checkbox"/> 본 정보는 제3자에게 제공되지 않으며 서비스 시작시 알림용으로만 사용됩니다.</div>
          
          <button disabled={buttonState} onClick={handleClick} className={buttonState===false ? "btn btn-lg btn-info" : ""}>
          신청하기
          </button>

        </form>


      </div>
      <img src={Mob6} alt="Logo" className="image-container" />
      <img src={Mob7} alt="Logo" className="image-container" />

</div>

    </div>
  );
}

export default Home;
