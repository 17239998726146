import React from "react";
import ReactDom from "react-dom";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/Home"

const App = () => {
  return (
    <Router>
      <div className="all-container">
          {/* <Navbar /> */}
        
          {/* <Route exact path="/*"> */}
            <Home />
          {/* </Route> */}
          {/* <Footer /> */}
        </div>
    </Router>
  );
};


ReactDom.render(<App />, document.getElementById("root"));
